
.exlyBodyContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exlyBranding {
  position: absolute;
  top: 10px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 1;
  color: #7b61ff;
}

.exlyBranding > img {
  margin: 16px 10px 0 0;
}

.exlyBranding > h1 {
  font-size: 1.5rem;
  font-weight: bold;
}
