.meetingNotStartedContainer {
  text-align: center;
}

.meetingNotStartedContainer > h1 {
  font-size: 1.5rem;
}

.buttonsConatainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.goBackButton {
  color: #493ab1;
  font-weight: bold;
  margin-right: 20px;
}

.refreshPageButton {
  background-color: #493ab1;
  color: white;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: bold;
}
